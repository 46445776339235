import React from "react"

import { Layout } from "../../components/Layout"
import { SEO } from "../../components/SEO"

const PrivacyPolicyPage = () => (
  <Layout>
    <SEO
      description="Your privacy is critically important to us."
      title="Privacy Policy"
    />
    <section className="container container-margin-bottom">
      <h1>Privacy Policy</h1>
      <p>Your privacy is critically important to us.</p>
      <p>
        It is Specter Socks's policy to respect your privacy regarding any
        information we may collect while operating our website. This Privacy
        Policy applies to https://www.spectersocks.com/ (hereinafter, "us",
        "we", or "https://www.spectersocks.com/"). We respect your privacy and
        are committed to protecting personally identifiable information you may
        provide us through the Website. We have adopted this privacy policy
        ("Privacy Policy") to explain what information may be collected on our
        Website, how we use this information, and under what circumstances we
        may disclose the information to third parties. This Privacy Policy
        applies only to information we collect through the Website and does not
        apply to our collection of information from other sources. This Privacy
        Policy, together with the Terms and conditions posted on our Website,
        set forth the general rules and policies governing your use of our
        Website. Depending on your activities when visiting our Website, you may
        be required to agree to additional terms and conditions.
      </p>

      <h3>Website Visitors</h3>
      <p>
        Like most website operators, Specter Socks collects
        non-personally-identifying information of the sort that web browsers and
        servers typically make available, such as the browser type, language
        preference, referring site, and the date and time of each visitor
        request. Specter Socks's purpose in collecting non-personally
        identifying information is to better understand how Specter Socks's
        visitors use its website.
      </p>

      <h3>Gathering of Personally-Identifying Information</h3>
      <p>
        Certain visitors to Specter Socks's websites choose to interact with
        Specter Socks in ways that require Specter Socks to gather
        personally-identifying information. The amount and type of information
        that Specter Socks gathers depends on the nature of the interaction. For
        example, we ask visitors who sign up for our newsletter at
        https://www.spectersocks.com/ to provide an email address.
      </p>

      <h3>Security</h3>
      <p>
        The security of your Personal Information is important to us, but
        remember that no method of transmission over the Internet, or method of
        electronic storage is 100% secure. While we strive to use commercially
        acceptable means to protect your Personal Information, we cannot
        guarantee its absolute security.
      </p>

      <h3>Links To External Sites</h3>
      <p>
        Our Service may contain links to external sites that are not operated by
        us. If you click on a third party link, you will be directed to that
        third party's site. We strongly advise you to review the Privacy Policy
        and terms and conditions of every site you visit. We have no control
        over, and assume no responsibility for the content, privacy policies or
        practices of any third party sites, products or services.
      </p>

      <h3>Aggregated Statistics</h3>
      <p>
        Specter Socks may collect statistics about the behavior of visitors to
        its website. Specter Socks may display this information publicly or
        provide it to others. However, Specter Socks does not disclose your
        personally-identifying information.
      </p>

      <h3>Cookies</h3>
      <p>
        To enrich and perfect your online experience, Specter Socks uses
        "Cookies", similar technologies and services provided by others to
        display personalized content, appropriate advertising and store your
        preferences on your computer. A cookie is a string of information that a
        website stores on a visitor's computer, and that the visitor's browser
        provides to the website each time the visitor returns. Specter Socks
        uses cookies to help Specter Socks identify and track visitors, their
        usage of https://www.spectersocks.com/, and their website access
        preferences. Specter Socks visitors who do not wish to have cookies
        placed on their computers should set their browsers to refuse cookies
        before using Specter Socks's websites, with the drawback that certain
        features of Specter Socks's websites may not function properly without
        the aid of cookies. By continuing to navigate our website without
        changing your cookie settings, you hereby acknowledge and agree to
        Specter Socks's use of cookies.
      </p>

      <h3>Privacy Policy Changes</h3>
      <p>
        Although most changes are likely to be minor, Specter Socks may change
        its Privacy Policy from time to time, and in Specter Socks's sole
        discretion. Specter Socks encourages visitors to frequently check this
        page for any changes to its Privacy Policy. Your continued use of this
        site after any change in this Privacy Policy will constitute your
        acceptance of such change.
      </p>

      <h3>Credit & Contact Information</h3>
      <p>
        This privacy policy was created at
        https://termsandconditionstemplate.com/privacy-policy-generator/. If you
        have any questions about this Privacy Policy, please contact us via or
        phone.
      </p>
    </section>
  </Layout>
)

export default PrivacyPolicyPage
